#footer {
	margin: "25px" 0;
	width: 100%;
	text-align: center;
    padding: 15px 0;
    transition: ease-in-out all 400ms;
}

#footer a {
	text-decoration: none;
}

@media only screen and (max-width: 900px) {
	#footer {
        text-align: left;
        transition: ease-in-out all 400ms;
	}
}
