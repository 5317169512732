
@font-face {
    font-family: 'Poppins';
    font-display: swap;
    src: url(WoffFonts/Poppins-Regular.woff2);
} 

@font-face {
    font-family: 'Poppins Bold';
    font-display: swap;
    src: url(WoffFonts/Poppins-Bold.woff2);
} 

@font-face {
    font-family: 'Pacifico';
    font-display: swap;
    src: url(WoffFonts/Pacifico-Regular.woff);
}

