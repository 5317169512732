#background {
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-items: center;
	transition: ease-in-out all 400ms;
}

#background_image {
	height: 600px;
	width: 100%;
	object-fit: cover;
	transition: ease-in-out all 400ms;
}

#credit {
	text-align: right;
	transition: ease-in-out all 400ms;
}

#credit a {
	text-decoration: none;
}

@media only screen and (max-width: 900px) {
	#background {
		display: block;
		margin-top: 100px;
		transition: ease-in-out all 400ms;
	}

	#background_image {
		display: none;
		transition: ease-in-out all 400ms;
	}

	#credit {
		display: none;
		transition: ease-in-out all 400ms;
	}
}
