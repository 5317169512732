#solutions {
	margin: 75px auto;
	transition: ease-in-out all 400ms;
}

#projects {
	margin: 35px auto 20px auto;
	text-align: center;
	display: flex;
}

@media only screen and (max-width: 738px) {
	#projects {
		margin: 35px auto 20px auto;
		text-align: center;
		display: block;
		transition: ease-in-out all 400ms;
	}
}
