#modal_container {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	display: block;
	transition: all ease-in-out 400ms;
	overflow: auto;
}

#content_position {
	width: 40%;
	height: 100%;
	margin: 0 auto;
	position: fixed;
	left: 0;
	right: 0;
	overflow: auto;
	transition: ease-in-out all 400ms;
}

#content_container {
	padding: 50px;
}

#update_state {
	color: grey;
	cursor: pointer;
	margin-bottom: 15px;
}

#content_title {
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 25px;
}

#content {
	font-size: 16px;
	text-align: justify;
}

@media only screen and (max-width: 900px) {
	#content_position {
		width: 100%;
		transition: ease-in-out all 400ms;
	}
}
