.card {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.0);
    padding: 15px 20px;
    margin: 10px 2.5%;
    text-align: left;
    flex: 1;
}

.card_title{
    font-size: 20px;
    margin: 0 0 30px 0;
}

.card_description{
    font-size: 16px;
}

.card_visit_btn{
    margin: 25px 0 0 0;
}

.card a{
    font-size: 16px;
    text-decoration: overline;
}
