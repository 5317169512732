body {
	font-family: "Poppins", sans-serif;
	padding: 0;
	margin: 0;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-size-adjust: 0.58;
	font-size: 14px;
	transition: all ease-in-out 400ms;
}

/* width */
::-webkit-scrollbar {
	width: 10px;
	cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
	background: #fafafa;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #bbb;
	transition: ease-in-out all 400ms;
}

#app-blue {
	height: 100%;
	width: 100%;
	position: fixed;
	overflow: auto;
}

#app-sec {
	height: 100%;
	width: 100%;
	position: fixed;
	overflow: auto;
}

#app-blue::-webkit-scrollbar-thumb:hover {
	background: #207eb4;
	transition: ease-in-out all 400ms;
}

#app-sec::-webkit-scrollbar-thumb:hover {
	background: #ea415d;
	transition: ease-in-out all 400ms;
}

.image_section {
	flex: 1;
	margin-left: 25px;
	position: relative;
}

.intro_section {
	flex: 1;
}

.intro_section a {
	text-decoration: none;
	color: black;
}

#image_section_about {
	flex: 1;
	margin-right: 25px;
	position: relative;
	text-align: center;
	display: flex;
}

.header {
	text-align: center;
	font-size: 28px;
	font-family: "Poppins Bold", sans-serif;
}

.intro_title {
	font-size: 28px;
	font-family: "Poppins Bold", sans-serif;
}

.intro_text {
	margin-top: 15px;
	line-height: 1.8em;
	font-size: 16px;
}

.solutions_btn {
	text-transform: uppercase;
	text-align: center;
	margin-top: 50px;
	padding: 10px;
	cursor: pointer;
	width: 125px;
	border-radius: 35px;
	transition: all ease-in-out 400ms;
}

.solutions_btn:hover {
	box-shadow: 10px -10px 0 #f0f0f0;
	transition: all ease-in-out 400ms;
}

.page {
	width: 80%;
	margin: 0 auto;
}

@media only screen and (max-width: 900px) {
	.header {
		text-align: left;
	}

	.image_section {
		margin-left: 0;
	}

	#image_section_about {
		margin-right: 0;
	}
}
