#contact_section {
	margin: 35px auto;
	display: flex;
	align-items: center;
	justify-items: center;
	transition: ease-in-out all 400ms;
}

#map {
	width: 100%;
	height: 450px;
	margin-right: 25px;
	transition: ease-in-out all 400ms;
}

.format {
	margin: 15px 0;
}

.input {
	border: solid 1px #f0f0f0;
	padding: 10px;
	width: 100%;
	font-family: "Poppins", sans-serif;
	transition: ease-in-out all 400ms;
}

#textarea {
	border: solid 1px #f0f0f0;
	padding: 10px;
	width: 100%;
	min-width: 100%;
	max-width: 100%;
	height: 150px;
	min-height: 150px;
	max-height: 150px;
	font-family: "Poppins", sans-serif;
	resize: none;
	transition: ease-in-out all 400ms;
}

#submit_btn {
	outline: 0;
	text-transform: uppercase;
	padding: 10px 25px;
	border-radius: 35px;
	background: transparent;
	cursor: pointer;
}

@media only screen and (max-width: 900px) {
	#contact_section {
		display: block;
		transition: ease-in-out all 400ms;
	}

	#map {
		width: 100%;
		height: 250px;
		margin-right: 0;
		transition: ease-in-out all 400ms;
	}

	.input {
		padding: 10px 0;
		margin-right: 10px;
		transition: ease-in-out all 400ms;
	}

	#textarea {
		padding: 10px 0;
		transition: ease-in-out all 400ms;
	}
}
