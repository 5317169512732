#about {
	margin: 50px auto;
	display: flex;
	align-items: center;
	justify-items: center;
	transition: ease-in-out all 400ms;
}

.image_style{
	flex: 1;
	transition: ease-in-out all 400ms;
}

#ceo_image {
	border-radius: 100%;
	width: 100px;
	height: 100px;
	object-fit: cover;
	border: solid 2px #f0f0f0;
	filter: grayscale(1);
	transition: all ease-in-out 400ms;
}

#ceo_image:hover{
	filter: grayscale(0);
	transition: all ease-in-out 400ms;
}

#center_tags {
    margin-top: 10px;
}

#ceo_name {
	font-size: 16px;
	font-weight: 700;
}

#ceo_tag {
	font-size: 12px;
	color: grey;
	margin-top: 10px;
}

@media only screen and (max-width: 900px){
	#about {
		display: block;
		word-break: break-all;
        transition: ease-in-out all 400ms;
	}

	#ceo_image {
		width: 60px;
		height: 60px;
	}

	#ceo_name {
		display: none;
	}

	#ceo_tag {
		display: none;
	}
}
